<template>
  <div>
    <v-row align="center" justify="center">
      <v-col class="col-banner">
        <v-img src="@/assets/banner.jpg" alt="Banner"></v-img>
      </v-col>
    </v-row>

    <v-row class="pa-5" align="center" justify="center">
      <v-col cols="12">
        <h1 class="center blue--text">¿QUÉ HACEMOS?</h1>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="250">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-tools</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Consultoría de Software
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Ofrecemos servicio de análisis y evaluación, brindando soluciones
            eficientes para su negocio mejorando la calidad de la operación de
            sus procesos.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="250">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-store</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">Comercio Electrónico</h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Plataforma completa para que puedas tener tu tienda virtual, vender
            y administrar tus clientes.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="250">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-project-diagram</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Software de Gestión de Proyectos
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Tienes muchos proyectos, equipos de trabajos grande y necesitas
            colaboración y comunicación a lo largo de todo el ciclo de vida del
            proyecto, tenemos la solución.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="250">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-home</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Portal Agencia Inmobiliaria
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Portal web para administración de bienes inmuebles
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="250">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-cash-register</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Sistema POS
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Software en línea para la gestión de inventarios y ventas en punto
            de venta.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="250">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-hotel</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Sistema de Reservas
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Sistema de reservas o Booking, ideal para hoteles, restaurantes,
            eventos, etc.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="250">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-user-tie</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">Web Corporativas</h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Si quieres una solución rápida, sencilla y económica de mostrarle a
            tus clientes y proveedores lo que ofreces en una pagina moderna con
            muchas plantillas y en uno de los gestores de contenido mas usados
            en el mundo, te gustara esta opción.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="250">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-cogs</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">Software a la Medida</h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Te hacemos una consultoría especializada para escoger la tecnología
            y la mejor herramienta para tu solución personalizada.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="250">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-window-maximize</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">Desarrollo Web</h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Aplicaciones web con marcos de trabajo profesionales, especiales
            para sistemas robustos y gigantes.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col>
        <v-card min-height="250">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-mobile-alt</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">Aplicaciones Móviles</h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Desarrollamos aplicaciones móviles en Android o iOs a la necesidad
            de tu marca.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="250">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-desktop</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Aplicaciones de Escritorio
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Aplicaciones que no necesitan un navegador para funcionar, basados
            en sistemas windows, que pueden funcionar en tiempo real o
            desconectados de internet.
          </v-card-subtitle>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="250">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-space-shuttle</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Alta Disponibilidad
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Si sufres de sistemas lentos y caídas por el alto volumen de
            procesos y personas que se conectan a tu sistema, esta solución te
            gustara.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="250">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-cloud</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Servicios Web o Api
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Si necesitas exponer o recibir información segura para que tus
            proveedores o terceros se puedan conectar a tu sistema, esta es la
            mejor solución.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="250">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-server</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Servidores y Dominios
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Si necesitas exponer o recibir información segura para que tus
            proveedores o terceros se puedan conectar a tu sistema, esta es la
            mejor solución.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="250">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-mail-bulk</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Correos Corporativos
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Necesitas tener correos con tu marca, nosotros te brindamos la
            solución con aplicación móvil.
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="pa-5" align="center" justify="center">
      <v-col cols="12">
        <h1 class="center text-uppercase blue--text">
          Características de nuestros desarrollos
        </h1>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="12" sm="12" md="4" lg="4" xl="3">
        <v-card min-height="200">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-image</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">Diseños modernos</h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Te garantizamos páginas asombrosas con experiencias modernas.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="3">
        <v-card min-height="200">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-window-restore</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">Diseño adaptable</h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Todas las aplicaciones web que desarrollamos son adaptables a
            cualquier dispositivo o tamaños de pantalla.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="3">
        <v-card min-height="200">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-shield-alt</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">Seguridad</h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Las aplicaciones que desarrollamos tienen muy buenas practicas de
            seguridad y mantenimiento.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="3">
        <v-card min-height="200">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-database</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">Bases de datos</h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Trabajamos con bases de datos SQL, noSQL y Multimodelo.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="3">
        <v-card min-height="200">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-code</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">Tecnologías</h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Nuestras aplicaciones son desarrolladas en lenguajes de programación
            modernos, como lo es PHP, .NET y JAVASCRIPT.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="3">
        <v-card min-height="200">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-server</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">Servidores propios</h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Contamos con servidores propios para alojar y darle un muy buen
            mantenimiento a todos nuestros clientes.
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  metaInfo() {
    return {
      title: "Fénix Software"
    };
  },
  data() {
    return {};
  }
};
</script>
